/*
 * ----------------------------------------------------------------------------------------
 Author       : Onepageboss
 Template Name: AISHA Material Design HTML5 Agency Template
 Version      : 1.0
 
 ____                   _____                             ____     ____     _____    _____
 / __ \                 |  __ \                           |  _ \   / __ \   / ____|  / ____|
 | |  | |  _ __     ___  | |__) |   __ _    __ _    ___    | |_) | | |  | | | (___   | (___
 | |  | | | '_ \   / _ \ |  ___/   / _` |  / _` |  / _ \   |  _ <  | |  | |  \___ \   \___ \
 | |__| | | | | | |  __/ | |      | (_| | | (_| | |  __/   | |_) | | |__| |  ____) |  ____) |
 \____/  |_| |_|  \___| |_|       \__,_|  \__, |  \___|   |____/   \____/  |_____/  |_____/
 __/ |
 |___/
 * ----------------------------------------------------------------------------------------
 */

(function ($) {
    'use strict';
    
    jQuery(document).ready(function () {
        
        /*
         * ----------------------------------------------------------------------------------------
         *  PRELOADER
         * ----------------------------------------------------------------------------------------
         */
        if($('#welcome-slide-carousel').length){
            $('#welcome-slide-carousel').on('slid', function () {
                $('.preloader').fadeOut();
                $('.preloader-area').delay(350).fadeOut('slow');
            })
            $('#welcome-slide-carousel').trigger('slid');
        }else{
            $('.preloader').fadeOut();
            $('.preloader-area').delay(350).fadeOut('slow');
        }
 
        // $(window).load(function () {
        //     $('.preloader').fadeOut();
        //     $('.preloader-area').delay(350).fadeOut('slow');
        // });
        /*
         * ----------------------------------------------------------------------------------------
         *  CHANGE MENU BACKGROUND
         * ----------------------------------------------------------------------------------------
         */
        $(window).on('scroll', function () {
            if ($(window).scrollTop() > 200) {
                $('.header-top-area').addClass('menu-bg');
            } else {
                $('.header-top-area').removeClass('menu-bg');
            }
        });
        
        
        
        /*
         * ----------------------------------------------------------------------------------------
         *  SMOTH SCROOL
         * ----------------------------------------------------------------------------------------
         */
        $('a.smoth-scroll').on("click", function (e) {
            var anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $(anchor.attr('href')).offset().top - 50
            }, 1000);
            e.preventDefault();
        });
        
        /*
         * ----------------------------------------------------------------------------------------
         *  WORK JS
         * ----------------------------------------------------------------------------------------
         */
        
        $('.work-inner').mixItUp();
        
        
        /*
         * ----------------------------------------------------------------------------------------
         *  TESTIMONIAL
         * ----------------------------------------------------------------------------------------
         */
        $(".testimonial-list").owlCarousel({
            items: 1,
            autoPlay: true,
            navigation: true,
            navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            itemsDesktop: [1199, 1],
            itemsDesktopSmall: [980, 1],
            itemsTablet: [768, 1],
            itemsTabletSmall: false,
            itemsMobile: [479, 1],
            pagination: false,
            autoHeight: true
            
        });
        
        /*
         * ----------------------------------------------------------------------------------------
         *  GOOGLE MAP
         * ----------------------------------------------------------------------------------------
         */
        var contact = {
            "lat": "59.9387218",
            "lon": "30.37313549999999"
        }; //Change a map coordinate here!
        try {
            $(window).load(function(){
                $('.map').gmap3({
                    action: 'addMarker',
                    latLng: [contact.lat, contact.lon],
                    map: {
                        center: [contact.lat, contact.lon],
                        zoom: 14
                    },
                }, {
                    action: 'setOptions',
                    args: [{
                        scrollwheel: false
                    }]
                });
            })
           
        } catch (err) {
            
        }
        /*
         * ----------------------------------------------------------------------------------------
         *  EXTRA JS
         * ----------------------------------------------------------------------------------------
         */
        $(document).on('click', '.navbar-collapse.in', function (e) {
            if ($(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle') {
                $(this).collapse('hide');
            }
        });
        $('body').scrollspy({
            target: '.navbar-collapse',
            offset: 195
        });
        
        /*
         * ----------------------------------------------------------------------------------------
         *  SCROOL TO UP JS
         * ----------------------------------------------------------------------------------------
         */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 250) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });
        $('.scrollup').on("click", function () {
            $("html, body").animate({
                scrollTop: 0
            }, 800);
            return false;
        });
        
    });
    
    ;$(function(){
        var form = $('#form');
        if(form.length){
            var options = {
                focusInvalid: $(window).width() <= 768 ? false : true,
                rules : {
                    name : {
                        required : true
                    },
                    email : {
                        required : true
                        
                    }
                },
                messages : {
                    name: {
                        required: "Введите имя"
                    },
                    email: {
                        required: "Введите Email",
                        email: "Введите правильный email"
                        
                    },
                    
                    
                },
                highlight: function(element, errorClass) {
                    $(element).addClass(errorClass);
                },
                unhighlight: function(element, errorClass) {
                    $(element).removeClass(errorClass);
                    $(element).addClass('valid');
                },
                submitHandler: function (form) {
                    var url = $(form).data('ajax-url');
                    if($(form).valid()){
                        $.ajax({
                            method: 'POST',
                            url: url,
                            data: $(form).serialize()
                        }).done(function(data) {
                            if(data.success ){
                                $('.js-modal, .js-overlay').fadeIn();
                                setTimeout(function(){
                                    $('.js-overlay, .js-modal').fadeOut();
                                },5000) ;
                            }
                        }).fail(function( jqXHR, textStatus ) {
                            alert( "Request failed: " + textStatus );
                        });
                    }else{
                        $('.error-checkbox').fadeIn();
                    }
                    return false; // required to block normal submit since you used ajax
                }
            };
            //init validate
            form.each(function(i, el){
                $(el).validate(options);
            })
        }
        $('.form-control').focus(function(){
            $(this).siblings('.error').remove();
            $(this).attr('placeholder', '');
            
        })
        $('.form-control').focusout(function () {
            $(this).attr('placeholder', $(this).data('holder'));
        });
        $('.js-overlay').on('click', function (e) {
            $(this).fadeOut();
            $('.js-modal').fadeOut();
        })
        $('.js-close').on('click', function (e) {
            $('.js-overlay').fadeOut();
            $('.js-modal').fadeOut();
        })
    })
    
    
    
})(jQuery);